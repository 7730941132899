import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const getCloudinaryImageUrl = ({ image, width = 1200 }) =>
  image.replace(`/upload/`, `/upload/c_scale,w_${width}/`);

const MetaComponent = ({
  title,
  siteName,
  canonical,
  description,
  link,
  image = '',
  width = 1200,
  height = 630,
}) => {
  const ogImage = getCloudinaryImageUrl({ image, width, height });

  return image !== '' ? (
    <Helmet titleTemplate={`%s | H2 Life`}>
      <title>{title}</title>
      <link rel='canonical' href={canonical} />
      <meta name='description' content={description} />
      <meta property='og:site_name' content={siteName} />
      <meta property='og:title' content={title} />
      <meta property='og:url' content={link} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={ogImage} />
      <meta property='og:image:secure_url' content={ogImage} />
      <meta property='og:image:type' content='image/jpeg' />
      <meta property='og:image:width' content={width} />
      <meta property='og:image:height' content={height} />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:url' content={link} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={ogImage} />
    </Helmet>
  ) : (
    <Helmet titleTemplate={`%s | H2 Life`}>
      <title>{title}</title>
      <link rel='canonical' href={canonical} />
      <meta name='description' content={description} />
      <meta property='og:site_name' content={siteName} />
      <meta property='og:title' content={title} />
      <meta property='og:url' content={link} />
      <meta property='og:description' content={description} />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:url' content={link} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
    </Helmet>
  );
};

MetaComponent.propTypes = {
  title: PropTypes.string,
  siteName: PropTypes.string,
  canonical: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string,
};

export default MetaComponent;
